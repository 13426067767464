import React, { useState, useEffect } from 'react';
import { HashLink } from 'react-router-hash-link';

function Cookies() {
  const [cookiesAccepted, setCookiesAccepted] = useState(localStorage.getItem('cookiesAccepted'));

  useEffect(() => {
    const checkCookies = () => {
      if (!cookiesAccepted) {
        setTimeout(() => {
          const cookiesContainer = document.getElementById('cookiesContainer');
          cookiesContainer.classList.add('show');
        }, 9000); // Delay of 4 seconds (4000 milliseconds)
      }
    };

    checkCookies();
  }, [cookiesAccepted]);

  const acceptCookies = () => {
    localStorage.setItem('cookiesAccepted', 'true');
    setCookiesAccepted(true);
    var cookiesContainer = document.getElementById('cookiesContainer');
    cookiesContainer.style.display = 'none';
  };

  const rejectCookies = () => {
    localStorage.setItem('cookiesAccepted', 'false');
    setCookiesAccepted(false); 
    var cookiesContainer = document.getElementById('cookiesContainer');
    cookiesContainer.style.display = 'none'; // Hide the cookies message after rejecting
  };

  return (
    <div className={`cookies-container`} id="cookiesContainer">
      <div className="row">
        <div className="col-md-9">
          <h2 className="cookies-title">Welcome to Haantlay</h2>
          <p className="cookies-message">At Haantlay, we are committed to providing you with the best possible experience. To make this website work properly, we sometimes place small data files called cookies on your device. By using Haantlay's website, you agree to our use of cookies. <HashLink smooth to="/en/cookie-policy" className="cookies-link" onClick={rejectCookies}>Learn more about cookies</HashLink></p>
        </div>
        <div className="col-md-3">        
          <button className="cookies-btn2" onClick={acceptCookies}>I Accept</button>
          <button className="cookies-btn1" onClick={rejectCookies}>Reject All</button>
        </div>
      </div>     
    </div>
  );
}

export default Cookies;
