import React, { useState, useEffect } from 'react';
import { HashLink } from 'react-router-hash-link';

const HelpSection = () => {
  useEffect(() => {
    document.title = "Haantlay Secures 2nd Position at IEEE Expo";
  }, []);

  return (
    <section id="help">
      <div className="container">
        <h1>How can we help you?</h1>
        <p>Are you ready to push boundaries and explore new frontiers of innovation?</p>
        <HashLink smooth to={"/contactus/"} id="help-btn">Let's Work Together</HashLink>
      </div>
    </section>
  );
};

function ExpoHighlight() {
  const [newsim1, setNewsim1] = useState(null);

  useEffect(() => {
    import('../../assets/img/blogs/blog1.jpg').then(image => {
      setNewsim1(image.default);
    });
  }, []);

  return (
    <div>
      <section id="blogs-con">
        <div className="container">
          <div className="col-md-12 front-div-op">
            <h1>Achievements</h1>
            <h2>Haantlay's <br /> Achievements & <br /> Milestones! </h2>
          </div>
        </div>
      </section>
      <section id="bgsev">
        <div className="container">
          <div className="row">
            <div className="col-md-6 vmc div1 pr-5 pl-0 bdiv">
              <h2 className="h1-hp">Haantlay Secures 2nd Position at IEEE Expo</h2>
            </div>
            <div className="col-md-6 p-0 div2">
              {newsim1 && <img src={newsim1} alt="" className="img-fluid blogimg" />}
            </div>
          </div>
        </div>
      </section>
     
      <section id="c-privacy">
        <div className="container cus-p">
          <div className="col-md-10 p-0">
            <h3>Haantlay Secures 2nd Position at IEEE Expo with Groundbreaking "haantlay.com" Project</h3>
            <p>In a remarkable achievement at the IEEE Expo held by the University of Chakwal on January 10, 2024, Haantlay, a leading software company, clinched the coveted 2nd position with its innovative project, "haantlay.com." This success underscores the company's commitment to pushing technological boundaries and contributing significantly to the software industry.</p>

            <h3>Innovative Showcase:</h3>
            <p>The spotlight of the event was on Haantlay's groundbreaking project, "haantlay.com," a dynamic website that not only showcased the company's technological prowess but also embodied its unwavering commitment to excellence.</p>
            
            <h3>Recognition and Dedication:</h3>
            <p>Standing out among a pool of exceptional entries, "haantlay.com" captured the attention of industry experts, scholars, and participants alike, emphasizing Haantlay's dedication to pushing the boundaries of technology.</p>
            
            <h3>Forging Tomorrow's Techscape Today:</h3>
            <p>Haantlay's success at the IEEE Expo aligns seamlessly with its slogan, "forging tomorrow's techscape today." This achievement is not just a celebration of past successes but a catalyst for the company to continue forging new paths in software development.</p>

            <h3>User-Centric Design and Functionality:</h3>
            <p>The "haantlay.com" project not only highlights technical prowess but also symbolizes a commitment to user-centric design and functionality. The website's dynamic features set a new standard, exemplifying Haantlay's capability to merge technology with a superior user experience.</p>

            <h3>Gratitude and Future Vision:</h3>
            <p>Expressing gratitude for the recognition, Haantlay's management sees this accolade as fuel for their team to continue pushing the envelope in software development. "haantlay.com" is envisioned as a stepping stone towards more groundbreaking projects, consistently living up to their slogan.</p>
            
            <h3>Anticipation for Future Innovation:</h3>
            <p>As Haantlay revels in the glory of securing the 2nd position at the IEEE Expo, the industry eagerly anticipates the next wave of innovation from this dynamic software company. With a commitment to excellence and a track record of pushing technology boundaries, Haantlay is poised to make enduring contributions, truly forging tomorrow's techscape today.</p>
          </div>
        </div>
      </section>
      <HelpSection />
    </div>
  );
}

export default ExpoHighlight;
