import React, { useState, useEffect, useRef } from 'react';

import {HashLink} from 'react-router-hash-link';

const AnotherSection = () => {
  
  useEffect(() => {
    document.title = "Order Confirmed! Thank You.";
  }, []);
  return (
  
       <div className='cus-h-h-h-h'>
      <section id="thanks-t1">
        <div className="container">
            <h2 className="process-h">Order Confirmed: Successfully Processed</h2>
            <p className="process-p">Congratulations on your successful order placement! We're processing it seamlessly, and you'll soon receive a confirmation email. Need assistance? Reach out via our <HashLink smooth to="/contactus/" className="process-a" >contact page.</HashLink></p>
        </div>
    </section>   
       </div>
  );
};

const App = () => {
  return (
    <div>
      <AnotherSection />
      {/* Include other sections/components as needed */}
    </div>
  );
};

export default App;
