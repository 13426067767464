import React, { useState, useEffect, useRef } from 'react';


import {HashLink} from 'react-router-hash-link';

const AnotherSection = () => {
  
  useEffect(() => {
    document.title = "Order Failed | Please Try Again.";
  }, []);
  return (
  
       <div className='cus-h-h-h-h'>
        <section id="thanks-t2">
        <div className="container">
            <h2 className="process-h">Order Error: Unsuccessful Placement</h2>
            <p className="process-p">Oops! It seems there was a glitch processing your order. Please double-check your information and try again. If issues persist, don't hesitate to <HashLink smooth to="/contactus/" className="process-a" >contact us </HashLink> for support.</p>
        </div>
    </section>   
       </div>
  );
};

const App = () => {
  return (
    <div>
      <AnotherSection />
      {/* Include other sections/components as needed */}
    </div>
  );
};

export default App;
