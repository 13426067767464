
import {HashLink} from 'react-router-hash-link';
import React, { useState, useEffect, useRef } from 'react';
const NotFoundPage = () => {
  
  useEffect(() => {
    document.title = "Page not found | Haantlay";
  }, []);
  return (
    <>
      <section id="not-found" className="">
        <div className="container">
          <div className="col-md-9 ">
            <h2>Page Not Found</h2>
            <HashLink smooth to={"/"} id="not-found-btn">RETURN TO HOMEPAGE</HashLink>
          </div>
        </div>
      </section>
      <section id="not-found-line">
        <h1>The requested page could not be found.</h1>
      </section>
    </>
  );
}

export default NotFoundPage;
