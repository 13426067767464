import React, { useState, useEffect } from 'react';
import video from '../assets/img/overview.mp4';
import {HashLink} from 'react-router-hash-link';

import { FaLinkedinIn } from "react-icons/fa";

const HelpSection = () => {
  return (
    <section id="help">
      <div className="container">
        <h1>How can we help you?</h1>
        <p>Are you ready to push boundaries and explore new frontiers of innovation?</p>
        {/* Use a form with a button */}
        
        
        <HashLink smooth to={"/contactus/"} id="help-btn">Let's Work Together</HashLink>
      </div>
    </section>
  );
};


function App() {
  useEffect(() => {
    document.title = "Company Overview | Haantlay";
  }, []);
  return (
    <>
    <div>
      <section id="we-are" className="">
        <div className="container">
          <div className="col-md-11 front-div-op">
            <h2>Forging <br />Tomorrow's <br />Techscape, Today!</h2>
            <HashLink smooth to={"/contactus/"} id="contact-button-nn">GET IN TOUCH</HashLink>
          </div>
        </div>
      </section>
      <section id="we-aree">
        <div className="row col-12 m-0 cus-row">
          <div className="col-md-6 order1 phy">
            <h1 className="c-h1">OUR PHILOSOPHY</h1>
            <p>Haantlay stands as a vanguard, embodying innovation, inclusivity, excellence, and agility in the sculpting of tomorrow's techscape. Harnessing technology as a catalyst for positive transformation, we spearhead cutting-edge advancements, delivering solutions that transcend prevailing industry norms. Our organizational culture fosters a milieu of creativity, cherishing diversity, and navigating challenges with unwavering resilience. The crux of our commitment finds articulation in our succinct yet potent slogan, "Forging Tomorrow's Techscape, Today!"</p>
          </div>
          <div className="col-md-6">
            <video autoPlay muted loop id="phi-video" poster="">
              <source src={video} type="video/webm" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </section>
      <section id="our-values">
        <div className="container cus-row">
          <div className="col-md-9">
            <h1 className="c-h1">OUR CORE VALUES</h1>
            <h2 className="c-h2">We dedicatedly master the power of technology in bespoke ways for our customers, guided by three core values:</h2>
          </div>
        </div>
        <div className="row col-md-9 c-d-vv cus-row">
          <div className="col-md-4 v-div d1">
            <h2>Integrity</h2>
            <p>Embody dependability, honesty, and transparency.</p>
          </div>
          <div className="col-md-4 v-div d2">
            <h2>Innovation</h2>
            <p>Act courageously, experiment, and make bold decisions.</p>
          </div>
          <div className="col-md-4 v-div">
            <h2>Dedication</h2>
            <p>Commit to tirelessly delivering precision and perfection.</p>
          </div>
        </div>
      </section>
      <section id="the-h-way">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="c-h1">THE HAANTLAY'S WAY</h1>
              <h2 className="c-h2">As a top IT company, we're uniquely positioned to partner with the world’s largest businesses in their transformation journeys.</h2>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-7 c-c-cards">
              <h1 className="way-h">Innovation at the Core:</h1>
              <p className="wayp">We go beyond boundaries, embracing a culture that values creative thinking, experimentation, and the relentless pursuit of groundbreaking solutions.</p>
            </div>
            <div className="col-md-7 c-c-cards">
              <h1 className="way-h">People First:</h1>
              <p className="wayp">Success is built on our diverse and talented team. The Haantlay's Way prioritizes well-being, growth, and a supportive work environment.</p>
            </div>
            <div className="col-md-7 c-c-cards">
              <h1 className="way-h">Customer-Centric Excellence:</h1>
              <p className="wayp">Our clients are the heart of our business. The Haantlay's Way ensures a commitment to delivering exceptional value and building lasting partnerships.</p>
            </div>
            <div className="col-md-7 c-c-cards">
              <h1 className="way-h">Agile and Adaptable:</h1>
              <p className="wayp">In the fast-paced world of technology, adaptability is key. Our agile approach allows us to navigate change with resilience, always ready for the next challenge.</p>
            </div>
            <div className="col-md-7 c-c-cards">
              <h1 className="way-h">Ethical Leadership:</h1>
              <p className="wayp">Integrity and ethical conduct are non-negotiable. The Haantlay's Way dictates leading with honesty, transparency, and responsibility.</p>
            </div>
            <div className="col-md-7 c-c-cards">
              <h1 className="way-h">Community Commitment:</h1>
              <p className="wayp">Beyond business, we actively contribute to communities through engagement, volunteerism, and unwavering social responsibility.</p>
            </div>
          </div>
        </div>
      </section>
      <section id="our-leader">
        <div className="container">
          <h1 className="c-h1">OUR LEADERSHIP</h1>
          <h2 className="c-h2">Our commitment goes beyond serving our own <br /> business and clients as we strive to have a positive <br /> impact on people and communities.</h2>
        </div>
        <div id="bod">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-4 mx-auto card-p">
                <div className="card bg-transparent">
                  <img src={require("../assets/img/pro1.jpg")} alt="" className="img-fluid" />
                </div>
                <p>Mehar Hasnat Ahmed</p>
                <p className="pp">Founder, Chairman & CEO</p>
                </div>
              <div className="col-md-4 mx-auto card-p">
                <div className="card bg-transparent">
                  <img src={require("../assets/img/pro2.jpg")} alt="" className="img-fluid" />
                </div>
                <p>Bilal Altaf Bilal</p>
                <p className="pp">Chief Operating Officer</p>
                </div>
            </div>
          </div>
        </div>
      </section>
    </div>
   <div>
     <HelpSection />
   </div>

    </>
  );
}

export default App;
