import React, { useState, useEffect, useRef } from 'react';

import { HashLink } from 'react-router-hash-link';


function TermsAndConditions() {
  
  useEffect(() => {
    document.title = "Terms of Engagement | Our Legal Framework";
  }, []);
  return (
    <>
      <section id="privacy" className="">
        <div className="container">
          <div className="col-md-10 front-div-op">
            <h2>Terms & Condition</h2>
            <HashLink smooth to={"/contactus/"} id="contact-button-m">GET IN TOUCH</HashLink>
          </div>
        </div>
      </section>
      <section id="c-privacy">
        <div className="container cus-p">
          <div className="col-md-10 p-0">
            <h3>Terms and Conditions for Haantlay</h3>
            <p><strong className="text-danger">Please read these terms and conditions carefully before using our services.</strong></p>

            <h3>Agreement</h3>
            <p>By accessing or using our services, you agree to be bound by these terms and conditions. If you do not agree with any part of these terms, please do not use our services.</p>

            <h3>Intellectual Property</h3>
            <p>All content, including but not limited to text, graphics, logos, and software, is the property of Haantlay and protected by applicable copyright and trademark laws.</p>

            <h3>User Conduct</h3>
            <p>You are responsible for your actions while using our services. <br/> <strong>You agree not to: </strong><br/>
              <ul className='blognumlist'>
                <li> <strong></strong>  Violate any laws or regulations. <br/></li>
                <li><strong></strong>  Infringe on the intellectual property rights of other. <br/></li>
                <li> <strong></strong>  Use our services for illegal or malicious purposes. <br/></li>
                <li><strong></strong>  Distribute or transmit harmful code, viruses, or malware.</li>
              </ul>
            </p>
            
    
            <h3>Termination</h3>
            <p>Haantlay reserves the right to terminate or suspend your access to our services at our discretion, without notice or liability.</p>

            <h3>Limitation of Liability</h3>
            <p>Haantlay is not liable for any direct, indirect, incidental, special, or consequential damages arising from your use of our services.</p>

            <h3>Changes to Terms</h3>
            <p>We may update these terms and conditions from time to time. By using our services, you agree to be bound by the most current version of these terms.</p>

            <h3>Privacy Policy for Haantlay</h3>
            <p>At Haantlay, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines our practices concerning the collection, use, and protection of your data. By using our services, you agree to the terms described below.</p>

            <h3>Information We Collect</h3>
            <p>
              <ul className='blognumlist'>
                <li> <strong>Personal Information: </strong>We may collect personal information such as your name, email address, phone number, and postal address when you interact with our website, products, or services. <br/> </li>
                <li><strong>Non-Personal Information:</strong> We may also collect non-personal information, including browser type, IP address, and device information to improve our services and user experience.</li>
              </ul>          
            </p>

            <h3>How We Use Your Information</h3>
            <p><strong className="text-success">We use your information for the following purposes:</strong><br/> 
              <ul className='blognumlist'>
                <li> <strong></strong> To provide and maintain our services. <br/> </li>
                <li> <strong></strong>  To communicate with you, respond to your inquiries, and send updates and promotional material <br/> </li>
                <li> <strong></strong>  To improve our services and tailor them to your needs. <br/> </li>
                <li> <strong></strong>  To monitor and analyze usage patterns to enhance our website and services.</li>
              </ul>
            </p>

            <h3>Security and Protection</h3>
            <p>We employ industry-standard security measures to protect your personal information. However, please be aware that no data transmission over the internet can be guaranteed to be 100% secure.</p>

            <h3>Sharing Your Information</h3>
            <p>We do not sell or disclose your personal information to third parties without your consent, except in cases where required by law.</p>

            <h3>Cookies</h3>
            <p>We use cookies to collect non-personal information about your interactions with our website. You can manage your cookie settings through your browser.</p>
          </div>
        </div>
      </section>
    </>
  );
}

function App() {
  return (
    <div>
      <TermsAndConditions />
    </div>
  );
}

export default App;
