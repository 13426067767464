import React, { useState } from 'react';

const SubscribeForm = () => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');

    const handleChange = (e) => {
        setEmail(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Email validation
        const emailExp = /^[^\s()<>@,;:\/]+@\w[\w\.-]+\.[a-z]{2,}$/i;
        if (!email || !emailExp.test(email)) {
            setError('Please enter a valid email');
            return;
        } else {
            setError('');
        }

        // Assuming form submission logic here
        try {
            const response = await fetch('./processe/subscribe.php', {
                method: 'POST',
                body: JSON.stringify({ email }),
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                window.open('/t4jH8aM6cT2sY9lR7D5vK1bX3nZ0mPF4jH8aM6cT2sY9lR7D5vK1bX3nZ0mPF4jH8aM6cT2sY9lR7D5vK1bX3nZ0mPF4jH8aM6cT2sY9lR7D5vK1bX3nZ0mPF4jH8aM6cT2sY9lR7D5vK1bX3nZ0mP', '_self');
            } else {
                window.open('/X9lK7rP3vE5nQ1mW3B0zG8hF2jT6uVX9lK7rP3vE5nQ1mW3B0zG8hF2jT6uVX9lK7rP3vE5nQ1mW3B0zG8hF2jT6uVX9lK7rP3vE5nQ1mW3B0zG8hF2jT6uVX9lK7rP3vE5nQ1mW3B0zG8hF2jT6uVX9lK7rP3vE5nQ1mW3B0zG8hF2jT6uV', '_self');
            }
        } catch (error) {
            console.error('Error:', error);
            window.open('/X9lK7rP3vE5nQ1mW3B0zG8hF2jT6uVX9lK7rP3vE5nQ1mW3B0zG8hF2jT6uVX9lK7rP3vE5nQ1mW3B0zG8hF2jT6uVX9lK7rP3vE5nQ1mW3B0zG8hF2jT6uVX9lK7rP3vE5nQ1mW3B0zG8hF2jT6uVX9lK7rP3vE5nQ1mW3B0zG8hF2jT6uV', '_self');
        }
    };

    return (
        <div className="col-md-6 footer-newsletter p-0">
            <h4>Subscribe</h4>
            <p>Stay updated on how the future of technology is shaping.</p>

            <form className="subscribe-form" onSubmit={handleSubmit}>
                <div className="form-row form-group cus-for">
                    <input
                        className={`form-control ${error ? 'error' : ''}`}
                        type="email"
                        name="email"
                        placeholder="Enter your email here"
                        value={email}
                        onChange={handleChange}
                        style={{ borderColor: error ? 'red' : '' }} // Apply border color conditionally
                        required
                    />
                    <button type="submit">Submit</button>
                </div>
                {error && <div className="validation-s" style={{ display: 'block' }}>{error}</div>}
            </form>
        </div>
    );
};

export default SubscribeForm;
