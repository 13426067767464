import React, { useState, useEffect } from 'react';
import {HashLink} from 'react-router-hash-link';

import phivideo from "../assets/img/cr.webm";

import img1 from "../assets/img/maps/1.png";
import img2 from "../assets/img/maps/2.png";
import img3 from "../assets/img/maps/3.png";
import img4 from "../assets/img/maps/4.png";
import img5 from "../assets/img/maps/5.png";
import img6 from "../assets/img/maps/6.png";
import img7 from "../assets/img/maps/7.png";
import img8 from "../assets/img/maps/8.png";
import img9 from "../assets/img/maps/9.png";
import img10 from "../assets/img/maps/10.png";
import img11 from "../assets/img/maps/11.png";
import img12 from "../assets/img/maps/12.png";
import img13 from "../assets/img/maps/13.png";
import img14 from "../assets/img/maps/14.png";
import img15 from "../assets/img/maps/15.png";

function Carousel() {
  return (
    <div className="sliderr">
    <div className="slide-trackk">
      <div className="slidee">
        <img src={img1} height="210" width="auto" alt="" />
      </div>
      <div className="slidee">
        <img src={img2} height="210" width="auto" alt="" />
      </div>
      <div className="slidee">
        <img src={img3} height="210" width="auto" alt="" />
      </div>
      <div className="slidee">
        <img src={img4} height="210" width="auto" alt="" />
      </div>
      <div className="slidee">
        <img src={img5} height="210" width="auto" alt="" />
      </div>
      <div className="slidee">
        <img src={img6} height="210" width="auto" alt="" />
      </div>
      <div className="slidee">
        <img src={img7} height="210" width="auto" alt="" />
      </div>
      <div className="slidee">
        <img src={img8} height="210" width="auto" alt="" />
      </div>
      <div className="slidee">
        <img src={img9} height="210" width="auto" alt="" />
      </div>
      <div className="slidee">
        <img src={img10} height="210" width="auto" alt="" />
      </div>
      <div className="slidee">
        <img src={img11} height="210" width="auto" alt="" />
      </div>
      <div className="slidee">
        <img src={img12} height="210" width="auto" alt="" />
      </div>
      <div className="slidee">
        <img src={img13} height="210" width="auto" alt="" />
      </div>
      <div className="slidee">
        <img src={img14} height="210" width="auto" alt="" />
      </div>
      <div className="slidee">
        <img src={img15} height="210" width="auto" alt="" />
      </div>
      <div className="slidee">
        <img src={img1} height="210" width="auto" alt="" />
      </div>
      <div className="slidee">
        <img src={img2} height="210" width="auto" alt="" />
      </div>
      <div className="slidee">
        <img src={img3} height="210" width="auto" alt="" />
      </div>
      <div className="slidee">
        <img src={img4} height="210" width="auto" alt="" />
      </div>
      <div className="slidee">
        <img src={img5} height="210" width="auto" alt="" />
      </div>
      <div className="slidee">
        <img src={img6} height="210" width="auto" alt="" />
      </div>
      <div className="slidee">
        <img src={img7} height="210" width="auto" alt="" />
      </div>
      <div className="slidee">
        <img src={img8} height="210" width="auto" alt="" />
      </div>
      <div className="slidee">
        <img src={img9} height="210" width="auto" alt="" />
      </div>
      <div className="slidee">
        <img src={img10} height="210" width="auto" alt="" />
      </div>
      <div className="slidee">
        <img src={img11} height="210" width="auto" alt="" />
      </div>
      <div className="slidee">
        <img src={img12} height="210" width="auto" alt="" />
      </div>
      <div className="slidee">
        <img src={img13} height="210" width="auto" alt="" />
      </div>
      <div className="slidee">
        <img src={img14} height="210" width="auto" alt="" />
      </div>
      <div className="slidee">
        <img src={img15} height="210" width="auto" alt="" />
      </div>
   
    </div>
  </div>
  );
}

const HelpSection = () => {
  return (
    <section id="help">
      <div className="container">
        <h1>How can we help you?</h1>
        <p>Are you ready to push boundaries and explore new frontiers of innovation?</p>
        {/* Use a form with a button */}
        
        
        <HashLink smooth to={"/contactus/"} id="help-btn">Let's Work Together</HashLink>
      </div>
    </section>
  );
};



const StepCard = ({ number, title, description, imagePath, altText }) => {

  useEffect(() => {
    document.title = "Join our team of technology professionals | Haantlay";
  }, []);


    return (
      // <div className="container">

      <div className="col-md-3">
        <div className="card">
          <img src={imagePath} className="card-img-top img-fluid mx-auto d-block" alt={altText} />
          <div className="card-body text-center">
            <h1 className="card-text">{number}</h1>
            <h2 className="card-text">{title}</h2>
            <p className="card-text">{description}</p>
          </div>
        </div>
      </div>
      // </div>
    );
  };
  
  const StepsSection = () => {
    return (
      <div className="row">
        <StepCard
          number="01"
          title="APPLY"
          description="Please browse through the job openings and submit your application for positions that match your skillset."
          imagePath={require("../assets/img/ic/1.png")}
          altText="Image 1"
        />
        <StepCard
          number="02"
          title="REVIEW"
          description="Our recruiters will review your application and match you with the best-fit opportunity."
          imagePath={require("../assets/img/ic/2.png")}
          altText="Image 2"
        />
        <StepCard
          number="03"
          title="INTERVIEWS"
          description="Our interviewing process is competency-based, designed to identify individuals who thrive."
          imagePath={require("../assets/img/ic/3.png")}
          altText="Image 3"
        />
        <StepCard
          number="04"
          title="ONBOARDING"
          description="Our onboarding process will refine and prepare you for your professional journey with Haantlay."
          imagePath={require("../assets/img/ic/4.png")}
          altText="Image 4"
        />
      </div>
    );
  };
  

const Careers = () => {
  return (
    <>
    <div>
      <section id="careers" className="">
        <div className="container">
          <div className="col-md-12 front-div-op">
            <h1>CAREERS</h1>
            <h2>Take your <br /> career to the <br /> next level</h2>
            <button id="apply">APPLY NOW</button>
          </div>
        </div>
      </section>

      <section id="car-det">
        <div className="container">
          <div className="row">
            <div className="col-md-6 c-on div1">
              <h2>We are from the people</h2>
              <p>We are a team of 6500+ people – with a purpose to thrive <br />in our value-driven culture and make a real and lasting <br />difference to the organization and their careers.</p>
            </div>
            <div className="col-md-6 p-0 i-mg div2">
              <img src={require("../assets/img/reel-color.webp")} alt="" className="img-fluid s-g " />
            </div>
          </div>
        </div>
      </section>

      <section id="car-car">
        <div className="container">
          <div className="row">
            <div className="col-md-12 c-on div1">
              <h1>CAREER TRACKS</h1>
              <h2>Techies, innovators, developers, and free-thinkers… you’ve come to the right place. Whether you’re an experienced professional or a recent graduate, working with Haantlay will give you opportunities to excel and achieve the global recognition that you deserve!</h2>
            </div>
          </div>

          <div className="row d-flex justify-content-around">
            <div className="img img-fluid">
              <img src={require("../assets/img/c-c1.webp")} alt="" />
              <h2 className="c-h1-c">Graduates</h2>
            </div>
            <div className="img img-fluid">
              <img src={require("../assets/img/c-c2.webp")} alt="" />
              <h2 className="c-h1-c">Experienced Professionals</h2>
            </div>
            <div className="img img-fluid">
              <img src={require("../assets/img/c-c3.webp")} alt="" />
              <h2 className="c-h1-c">Internship/MTO</h2>
            </div>
          </div>
        </div>
      </section>

      <section id="car-num">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2>Our people, our success</h2>
            </div>
            <div className="col-md-12 ">
              <div className="row">
                <div className="col-md-3">
                  <h1 className="c-c-n counter" data-target="1200">1200+</h1>
                  <p>Global Employee Count</p>
                </div>
                <div className="col-md-3">
                  <h1 className="c-c-n counter" data-target="14000">14000+</h1>
                  <p>Strong social media community</p>
                </div>
                <div className="col-md-3">
                  <h1 className="c-c-n counter" data-target="109">109+</h1>
                  <p>Total Training sessions</p>
                </div>
                <div className="col-md-3">
                  <h1 className="c-c-n counter" data-target="4564">4564+</h1>
                  <p>Total participants</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      

      <section id="cr-vd">
        <div className="container">
          <div className="row">
            <div className="cr-conte col-md-6 cd-2">
              <h1>Join our global team</h1>
              <h2>Our career opportunities also span around the world.</h2>
            </div>
            <div className="col-md-6 cd-1">
              <video autoPlay muted loop id="phi-video" poster="">
                <source src={phivideo} type="video/webm" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      </section>

      <section id="cr-gl">
        <div>
          <h1>GLOBAL PRESENCE</h1>
        </div>
        <div>
          {/* <div className="slider"> */}
            {/* <div className="slide-track"> */}
              {/* Insert your slider content here */}
            {/* </div> */}
          {/* </div> */}
          <Carousel />
          
        </div>
      </section>

      <section id="wo-ha">
        <div className="container">
          <h3>WORKING AT HAANTLAY</h3>
          <h2 className="text-center">We strive to create an exceptional environment for our employees, where they can fully indulge their creative side and thoroughly enjoy every moment of their experience.</h2>
          <div className="row">
            <div className="col-md-6 ctext">
              {/* <img src={require("../assets/img/w-p.webp" className="img-fluid c-img" alt="" /> */}
            </div>
            <div className="col-md-6 bdr">
              <div className="col-md-12 bdr1">
                <h1 className="ch-ch">Working at Haantlay</h1>
                <p className="cp-cp">Haantlay is a great place to start your working life and we offer our people the opportunity to feel good by doing good in their everyday work. Our supportive and inclusive environment enables you to learn, develop and be your best in the role that best suits you.</p>
              </div>
              <div className="col-md-12 bdr2">
                <h1 className="ch-ch">Trainees</h1>
                <p className="cp-cp">We offer a range of graduate and internship opportunities around the world that will expose you to real business challenges and allow your energy and fresh thinking to have a real impact.
                </p>
              </div>
              <div className="col-md-12 bdr3">
                <h1 className="ch-ch">Diversity & inclusion</h1>
                <p className="cp-cp">Haantlay practices a value-driven culture that promotes diversity and inclusion. We're working to create a fairer, more socially inclusive world – in our community at work.</p>
              </div>
              <div className="col-md-12 bdr4">
                <h1 className="ch-ch">Benefits</h1>
                <p className="cp-cp">Haantlay offers employee benefits that strive to attract and retain top talent. We also realize that as a global company, this can vary greatly from country to country, and we have tailored our benefit programs to meet the specific needs of employees in the markets of our offices.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="c-ic-s">
        <div className="container">
          <div className="h-det">
            <h2>OUR HIRING PROCESS</h2>
            <h1>As simple as it could be</h1>
          </div>
        </div>
        <div className="container mt-4">
          <div className="row">
            <div className="col-md-12">
              <StepsSection />
            </div>
          </div>
        </div>
      </section>
    </div>
    <div>
       <HelpSection />
    </div>
      </>
  );
};



export default Careers;
