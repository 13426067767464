import React, { useState, useEffect, useRef } from 'react';
import {HashLink} from 'react-router-hash-link';




function CookiePolicy() {
  
  useEffect(() => {
    document.title = "Baking Insights | Our Cookie Usage";
  }, []);
  return (
    
    <>
      <section id="privacy" className="">
        <div className="container">
          <div className="col-md-9 front-div-op">
            <h2>Cookie Policy</h2>
            <HashLink smooth to={"/contactus/"} id="contact-button-m">GET IN TOUCH</HashLink>
          </div>
        </div>
      </section>
      <section id="c-privacy">
        <div className="container cus-p">
          <div className="col-md-10 p-0">
            <h3>Cookies Policy for Haantlay</h3>
            <p>Welcome to Haantlay's Cookies Policy. This page aims to provide you with comprehensive information about the usage of cookies on our website. By accessing and using our website, you are consenting to the use of cookies in accordance with this policy.</p>

            <h3>Understanding Cookies</h3>
            <p>Cookies are small pieces of data stored on your device, whether it be a computer or mobile device, when you interact with a website. They serve various purposes, including enhancing website functionality, analyzing website usage patterns, and providing a personalized browsing experience.</p>

            <h3>Our Use of Cookies</h3>
            <p>At Haantlay, we utilize cookies for a range of purposes, each contributing to the overall functionality and performance of our website:</p>
            <p>
              <ul className='blognumlist'>
                <li> <p><strong>Essential Cookies:  </strong>These cookies are vital for the basic operation of our website. They enable features such as secure access, session management, and load balancing. Without these cookies, our website may not function properly.</p> </li>
                <li> <p> <strong>Analytical/Performance Cookies:  </strong>These cookies help us understand how visitors interact with our website. They collect anonymous data on factors such as page views, time spent on each page, and navigation paths. This information enables us to analyze and optimize the performance of our website.</p></li>
                <li> <p> <strong>Functionality Cookies:  </strong>Functionality cookies allow our website to remember choices you make and provide enhanced features tailored to your preferences. For example, they may remember your preferred language or region settings, improving your overall browsing experience.</p></li>
                <li> <p><strong>Targeting/Advertising Cookies:  </strong>These cookies are used to deliver personalized advertisements based on your interests and browsing behavior. They may also be used to limit the frequency of ads you see and measure the effectiveness of advertising campaigns.</p> </li>
              </ul>          
            </p>
          
            <h3>Third-Party Cookies</h3>
            <p>In addition to the cookies we set directly, we may also utilize third-party cookies provided by trusted partners. These cookies may be used for analytics, advertising, or other purposes in accordance with the respective privacy policies of the third-party providers.</p>

            <h3>Your Cookie Choices</h3>
            <p>Upon visiting our website, you will be presented with options to accept or reject non-essential cookies. By accepting cookies, you are agreeing to the use of cookies as outlined in this policy. However, you have the option to opt-out of non-essential cookies at any time by adjusting your browser settings or utilizing the cookie consent tool provided on our website. <br/> Please note that opting out of certain cookies may impact your browsing experience, and some website features may not function as intended.</p>

            <h3>Policy Updates</h3>
            <p>We may update this Cookies Policy periodically to reflect changes in our practices or in response to regulatory requirements. We encourage you to review this page regularly for the latest information on our use of cookies.</p>

            <h3>Contact Us</h3>
            <p>If you have any questions or concerns regarding our Cookies Policy, please don't hesitate to <HashLink smooth to={"/en/contactus"} className="b-link c-c-c-c-c">contact us</HashLink></p>

            <p className="b-italic">Thank you for choosing Haantlay. We strive to provide you with a seamless and personalized browsing experience on our website!</p>

          </div>
        </div>
      </section>  
    </>
  );
}

function App() {
  return (
    <div>
      <CookiePolicy />
    </div>
  );
}

export default App;
