import React, { useState, useEffect } from 'react';

import newsimg1 from '../assets/img/blogs/blog1.jpg';
import newsimg2 from '../assets/img/blogs/blog2.jpg';


import { HashLink } from 'react-router-hash-link';

const HelpSection = () => {
  return (
    <section id="help">
      <div className="container">
        <h1>How can we help you?</h1>
        <p>Are you ready to push boundaries and explore new frontiers of innovation?</p>
        {/* Use a form with a button */}
        
        
        <HashLink smooth to={"/contactus/"} id="help-btn">Let's Work Together</HashLink>
      </div>
    </section>
  );
};


function BlogSection() {
  useEffect(() => {
    document.title = "Explore Our Latest Company Updates! | Company News";
  }, []);
  return (
    <section id="blogs">
      <div className="container">
        <div className="col-md-12 front-div-op">
          <h1>COMPANY NEWS</h1>
          <h2>Explore Our <br /> Latest Company <br /> Updates!</h2>
        </div>
      </div>
    </section>
  );
}

function BlogContent() {
  return (
    <>
    <section id="blogs-c">
      <div className="blog-body">
        <HashLink smooth to={"/source/topics/company-news=Haantlay-Secures-2nd-Position-at-IEEE-Expo"}
        className="cus-a-a">
          <div className="row col-md-12 c-row p-0">
            <div className="col-md-9 b-contente d-b-1">
              <h2>Company News</h2>
              <h1>Haantlay Secures 2nd Position at IEEE Expo</h1>
              <p>
                In a remarkable achievement at the IEEE Expo held by the University of Chakwal on January 10, 2024, Haantlay, a leading software company <span>Read More...</span>
              </p>
            </div>
            <div className="col-md-3 d-b-2">
              <img src={newsimg1} className="img-fluid p-0 b-img" alt="Blog" />
            </div>
          </div>
        </HashLink>
      </div>
    </section>
    <section id="blogs-c">
      <div className="blog-body">
        <HashLink smooth to={"/source/topics/company-news=A-Triumph-in-Speed-Coding-at-the-WEC-Olympiad"}
        className="cus-a-a">
          <div className="row col-md-12 c-row p-0">
            <div className="col-md-9 b-contente d-b-1">
              <h2>Company News</h2>
              <h1>Leading the Pack: A Triumph in Speed Coding at the WEC Olympiad</h1>
              <p>
              The WEC Olympiad held at Wah Engineering College on the 5th and 6th of March brought together the brightest minds in Pakistan to showcase their talents across various disciplines. Among these, <span>Read More...</span>
              </p>
            </div>
            <div className="col-md-3 d-b-2">
              <img src={newsimg2} className="img-fluid p-0 b-img" alt="Blog" />
            </div>
          </div>
        </HashLink>
      </div>
    </section>
    </>
  );
}

function App() {
  return (
    <div className="App">
      <BlogSection />
      <BlogContent />
      <HelpSection />
    </div>
  );
}

export default App;