import React, { useState, useEffect, useRef } from 'react';

import {HashLink} from 'react-router-hash-link';

const AnotherSection = () => {
  
  useEffect(() => {
    document.title = "You’re Subscribed! Welcome to Our Community.";
  }, []);
  return (
  
       <div className='cus-h-h-h-h'>
          <section id="thanks-t5">
        <div className="container">
            <h2 className="process-h">Subscription Confirmed: Welcome to the Future Updates</h2>
            <p className="process-p">Thanks for subscribing! You're now part of our future updates. Explore the latest happenings, and for any queries, <HashLink smooth to="/contactus/" className="process-a" >contact us.</HashLink></p>
        </div>
    </section>  
       </div>
  );
};

const App = () => {
  return (
    <div>
      <AnotherSection />
      {/* Include other sections/components as needed */}
    </div>
  );
};

export default App;
