import React, { useState, useEffect, useRef } from 'react';

import {HashLink} from 'react-router-hash-link';

const SubscriptionError = () => {
  
  useEffect(() => {
    document.title = "Subscription Error | Please Check Your Details.";
  }, []);
  return (
    <div className='cus-h-h-h-h'>
    <section id="thanks-t6">
      <div className="container">
        <h2 className="process-h">Subscription Error: Unsuccessful</h2>
        <p className="process-p">There was an issue with your subscription. Retry, and if problems persist, get in touch with us via our <HashLink smooth to="/contactus/" className="process-a" >contact form</HashLink> for assistance.</p>
      </div>
    </section>
    </div>
  );
};

const App = () => {
  return (
    <div>
      {/* Other components */}
      <SubscriptionError />
      {/* Other components */}
    </div>
  );
};

export default App;
