import React, { useState, useEffect, useRef } from 'react';
import { HashLink } from 'react-router-hash-link';

const HelpSection = () => {
  useEffect(() => {
    document.title = "Leading the Pack: A Triumph in Speed Coding at the WEC Olympiad";
  }, []);

  return (
    <section id="help">
      <div className="container">
        <h1>How can we help you?</h1>
        <p>Are you ready to push boundaries and explore new frontiers of innovation?</p>
        <HashLink smooth to={"/contactus/"} id="help-btn">Let's Work Together</HashLink>
      </div>
    </section>
  );
};

function ExpoHighlight() {
  const [newsim2, setNewsim2] = useState(null);

  useEffect(() => {
    import('../../assets/img/blogs/blog2.jpg').then(image => {
      setNewsim2(image.default);
    });
  }, []);

  return (
    <div>
      <section id="blogs-con">
        <div className="container">
          <div className="col-md-12 front-div-op">
            <h1>Achievements</h1>
            <h2>Haantlay's <br /> Achievements & <br /> Milestones! </h2>
          </div>
        </div>
      </section>
      <section id="bgsev">
        <div className="container">
          <div className="row">
            <div className="col-md-6 vmc div1 pr-5 pl-0 bdiv">
              <h2 className="h1-hp">Leading the Pack: A Triumph in Speed Coding at the WEC Olympiad</h2>
            </div>
            <div className="col-md-6 p-0 div2">
              {newsim2 && <img src={newsim2} alt="" className="img-fluid blogimg" />}
            </div>
          </div>
        </div>
      </section>
      <section id="c-privacy">
        <div className="container cus-p">
          <div className="col-md-10 p-0">
                    
          <h3>Introduction:</h3>
            <p>
                The WEC Olympiad held at Wah Engineering College on the 5th and 6th of March brought together the brightest minds in Pakistan to showcase their talents across various disciplines. Among these, the "Speed Coding" module stood as a testament to the prowess of coding enthusiasts, offering a platform to demonstrate their skills under intense time constraints.
            </p>

            <h3>Setting the Stage - The WEC Olympiad</h3>
          
          
          <p>
            <li><strong>Name:</strong> WEC Olympiad <br /></li>
            <li><strong>Location:</strong> Wah Engineering College <br /></li>
            <li><strong>Date:</strong> 5th and 6th March <br /></li>
            <li><strong>Purpose:</strong> The WEC Olympiad aims to foster innovation and excellence among students and professionals across Pakistan. It serves as a melting pot of talent, providing participants with opportunities to showcase their skills in a competitive yet collaborative environment.</li>               
          </p>

            <h3>The Challenge: Speed Coding</h3>
            <p>
                Speed Coding emerged as one of the most challenging modules within the Olympiad, testing participants' ability to write efficient and error-free code within tight timeframes. Contestants were presented with a series of coding problems ranging in complexity, requiring quick thinking and problem-solving skills.
            </p>

            <h3>A Champion Emerges</h3>
            <p>
                Our company's founder and CEO, Mehar Hasnat Ahmed, stepped into the arena of Speed Coding with determination and expertise, ready to showcase his skills on a national stage. Armed with years of experience and a deep understanding of coding principles, he embraced the challenge with enthusiasm.
            </p>

            <h3>The Road to Victory</h3>
            <p>
                Navigating through rounds of intense coding challenges, Mehar Hasnat Ahmed demonstrated remarkable proficiency and strategic thinking, tackling each problem with precision and finesse. Utilizing a combination of algorithms, data structures, and programming techniques, he optimized his solutions to deliver exemplary performance.
            </p>

            <h3>Triumph at the Finish Line</h3>
            <p>
                After a grueling competition, Mehar Hasnat Ahmed emerged as the undisputed champion, claiming the first position in the Speed Coding module and solidifying his reputation as a coding maestro. His ability to deliver high-quality code under pressure set him apart from the competition, earning him accolades and admiration from peers and judges alike.
            </p>

            <h3>Implications for Our Company</h3>
            <p>
                Mehar Hasnat Ahmed's victory not only reflects his individual talent but also highlights the dedication and vision that drive our company forward. It serves as a testament to our commitment to excellence and innovation, reaffirming our position as a leader in the technology industry.
            </p>

            <h3>Inspiring Excellence</h3>
            <p>
                The WEC Olympiad served as a platform for collaboration and learning, inspiring participants to push the boundaries of their abilities and strive for excellence in their respective fields. Mehar Hasnat Ahmed's success serves as a source of inspiration for aspiring coders within our company and beyond, encouraging them to pursue their passions and aim for greatness.
            </p>

            <h3>Conclusion</h3>
            <p>
                As we celebrate Mehar Hasnat Ahmed's triumph in the WEC Olympiad, we are reminded of the values that define our company - a commitment to excellence, a passion for innovation, and a relentless pursuit of success. His victory serves as a shining example of what can be achieved through dedication, perseverance, and a drive to excel. As we look to the future, we are inspired to continue pushing the boundaries of what is possible, fueled by the spirit of innovation and a determination to make a lasting impact in the world of technology.
            </p>

          </div>
        </div>
      </section>
      <HelpSection />
    </div>
  );
}

export default ExpoHighlight;
