
function startCounterAnimation(counterElement, target, duration) {
    const startCount = 0;
    const interval = 10; // milliseconds
    const steps = duration / interval;
    const increment = target / steps;

    let currentCount = startCount;

    function updateCounter() {
        currentCount += increment;
        counterElement.textContent = Math.round(currentCount);

        if (currentCount >= target) {
            counterElement.textContent = target + '+'; // Append '+' at the end
            clearInterval(counterInterval);
        }
    }

    const counterInterval = setInterval(updateCounter, interval);
}

function isElementInViewport(el) {
    const rect = el.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}

function handleScroll() {
    const counters = document.querySelectorAll(".counter");

    counters.forEach(counterElement => {
        const isCounterVisible = isElementInViewport(counterElement);

        if (isCounterVisible && !counterElement.dataset.animationStarted) {
            const targetValue = parseInt(counterElement.getAttribute("data-target"), 10);
            startCounterAnimation(counterElement, targetValue, 2000); // Set duration to 2000 milliseconds (2 seconds)

            // Set a flag to avoid restarting the animation for the same element
            counterElement.dataset.animationStarted = true;
        }
    });
}

window.addEventListener("scroll", handleScroll);




