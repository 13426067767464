import React, { useState, useEffect, useRef } from 'react';


import {HashLink} from 'react-router-hash-link';

const AnotherSection = () => {
  
  useEffect(() => {
    document.title = "Oops! Something Went Wrong.";
  }, []);
  return (
  
       <div className='cus-h-h-h-h'>
           <section id="thanks-t3">
        <div className="container">
            <h2 className="process-h">Contact Attempt Unsuccessful </h2>
            <p className="process-p">We're sorry, but it seems there was an issue with your attempt to contact us. Please double-check your information and try again. If the problem persists, feel free to explore alternative ways to <HashLink smooth to="/contactus/" className="process-a" >get in touch</HashLink> with us.</p>
        </div>
    </section>   
       </div>
  );
};

const App = () => {
  return (
    <div>
      <AnotherSection />
      {/* Include other sections/components as needed */}
    </div>
  );
};

export default App;
