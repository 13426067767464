import React, { useState, useEffect, useRef } from 'react';

import {HashLink} from 'react-router-hash-link';

const AnotherSection = () => {
  
  useEffect(() => {
    document.title = "Message Sent Successfully!";
  }, []);
  return (
  
       <div className='cus-h-h-h-h'>
     <section id="thanks-t4">
        <div className="container">
            <h2 className="process-h">Thank you for contacting us</h2>
            <p className="process-p">We appreciate your interest in our company and look forward to staying in touch. If you have any questions or feedback, please don't hesitate to <HashLink smooth to="/contactus/" className="process-a" >contact us.</HashLink></p>
        </div>
    </section>   
       </div>
  );
};

const App = () => {
  return (
    <div>
      <AnotherSection />
      {/* Include other sections/components as needed */}
    </div>
  );
};

export default App;
