import React, { useState, useEffect, useRef } from 'react';
import {HashLink} from 'react-router-hash-link';
import aivid from '../assets/img/ai-haantlay.webm';
import vide from '../assets/img/vid2.webm';
import videoFile from '../assets/img/vid-in.webm';
import joinvid from '../assets/img/home-careers.webm';
import visimg from '../assets/img/mission.png';
import misimg from '../assets/img/vission.png';

import { TfiAngleRight } from 'react-icons/tfi';
import { TfiAngleLeft } from 'react-icons/tfi';

import { Carousel } from 'react-responsive-carousel';


const CarouselComponent = () => {

  useEffect(() => {
    document.title = "Forging Tomorrow's Techscape, Today! | Haantlay";
  }, []);

  return (
    
        <Carousel infiniteLoop autoPlay 
        showStatus={false} 
        showThumbs={false} 
        showIndicators={false}
        swipeable={false}
        interval={3000} renderArrowPrev={(onClickHandler, hasPrev, label) =>
          hasPrev && (
            <button type="button" onClick={onClickHandler} title={label} className="arrow-prev ">
              <TfiAngleLeft />
            </button>
          )
        } renderArrowNext={(onClickHandler, hasNext, label) =>
          hasNext && (
            <button type="button" onClick={onClickHandler} title={label} className="arrow-next">
              <TfiAngleRight />
            </button>
          )
        }>
          {/* Your carousel slides */}
          <div key="video1">
        <section id="video-intro">
          <div className="container con-ab">
            <div className="video-container">
              <video autoPlay muted loop id="background-video" poster="../img/p1.jpg">
                <source src={videoFile} type="video/webm" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="row">
              <div className="col-md-8 front-div-opp ">
                <h1 className="vidh">Navigate <br /> the Digital World Fearlessly</h1>
                <p className="vidp">Haantlay's Cyber Guardians Empower Fearless Navigation in the Digital World, Ensuring Safety.</p>
                <HashLink smooth to={"/contactus/"} id="contact-button-nn">GET IN TOUCH</HashLink>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div key="video2">
        <section id="video-intro">
          <div className="container con-ab">
            <div className="video-container">
              <video autoPlay muted loop id="background-video" poster="../img/p1.jpg">
                <source src={aivid} type="video/webm" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="row">
              <div className="col-md-8 front-div-opp ">
                <h1 className="vidh">Luxury <br /> reimagined through innovation</h1>
                <p className="vidp">Haantlay Sets New Digital Standards Where Innovation Seamlessly Intertwines with Luxury.</p>

                <HashLink smooth to={"/contactus/"} id="contact-button-nn">GET IN TOUCH</HashLink>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div key="video3">
        <section id="video-intro">
          <div className="container con-ab">
            <div className="video-container">
              <video autoPlay muted loop id="background-video" poster="../img/p1.jpg">
                <source src={vide} type="video/webm" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="row">
              <div className="col-md-8 front-div-opp ">
                <h1 className="vidh">Where AI <br /> Shapes Infinite Possibilities</h1>
                <p className="vidp">Haantlay Leads the Charge in AI, Pioneering Innovation to Reshape Tomorrow's Possibilities.</p>
                <HashLink smooth to={"/contactus/"} id="contact-button-nn">GET IN TOUCH</HashLink>
              </div>
            </div>
          </div>
        </section>
      </div>
        </Carousel>
     
  );
};

const VideoIntroSection = () => {
  return (
    <Carousel infiniteLoop autoPlay 
    showStatus={false} 
    showThumbs={false} 
    showIndicators = {false}
    interval={3000}
    >
      <div key="video1">
        <section id="video-intro">
          <div className="container con-ab">
            <div className="video-container">
              <video autoPlay muted loop id="background-video" poster="../img/p1.jpg">
                <source src={videoFile} type="video/webm" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="row">
              <div className="col-md-8 front-div-opp ">
                <h1 className="vidh">Navigate <br /> the Digital World Fearlessly</h1>
                <p className="vidp">Haantlay's Cyber Guardians Empower Fearless Navigation in the Digital World, Ensuring Safety.</p>
                <button id="contact-button">GET IN TOUCH</button>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div key="video2">
        <section id="video-intro">
          <div className="container con-ab">
            <div className="video-container">
              <video autoPlay muted loop id="background-video" poster="../img/p1.jpg">
                <source src={aivid} type="video/webm" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="row">
              <div className="col-md-8 front-div-opp ">
                <h1 className="vidh">Luxury <br /> reimagined through innovation</h1>
                <p className="vidp">Haantlay Sets New Digital Standards Where Innovation Seamlessly Intertwines with Luxury.</p>

                <button id="contact-button">GET IN TOUCH</button>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div key="video3">
        <section id="video-intro">
          <div className="container con-ab">
            <div className="video-container">
              <video autoPlay muted loop id="background-video" poster="../img/p1.jpg">
                <source src={vide} type="video/webm" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="row">
              <div className="col-md-8 front-div-opp ">
                <h1 className="vidh">Where AI <br /> Shapes Infinite Possibilities</h1>
                <p className="vidp">Haantlay Leads the Charge in AI, Pioneering Innovation to Reshape Tomorrow's Possibilities.</p>
                <button id="contact-button">GET IN TOUCH</button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Carousel>
  );
};

const AboutSection = () => {
  return (
    <section id="about">
      <div className="container con-ab">
        <div className="row">
          <div className="col-md-10 p-0 divx">
            <h2 className="h1-hp">About Us</h2>
            <p className="p-hp" data-aos="zoom-out-left">In the vibrant month of July 2023, a pioneering company emerged with a lofty ambition - to unearth and cultivate the brilliance of Pakistan's finest minds. Their noble mission is to foster local talent, igniting a beacon of growth and prosperity across the nation.</p>
          </div>
          <div className="col-md-6 vmc div1 pr-5 pl-0">
            <h2 className="h1-hp">Our mission</h2>
            <p className="p-hp">Haantlay is committed to uncovering and empowering Pakistan's hidden gems, fostering national growth through top-notch IT services. We aim to unleash the potential of skilled individuals, propelling Pakistan's advancement in the digital age.</p>
          </div>
          <div className="col-md-6 p-0 vmimg div2">
            <img src={visimg} alt='mission' className="img-fluid mvimg" />
          </div>
          <div className="col-md-6 p-0 vmimg div3">
            <img src={misimg} alt='vission' className="img-fluid mvimg" />
          </div>
          <div className="col-md-6 vmc div4 pl-5 pr-0">
            <h2 className="h1-hp">Our vision</h2>
            <p className="p-hp">To propel Pakistan to IT excellence, Haantlay envisions a vibrant community of highly skilled individuals driving technological innovation. Our goal is to see Pakistan's talent shine, contributing significantly to socio-economic development and global recognition.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

const WhyUsSection = () => {
  return (
    <section id="why-us" className="">
      <div className="container">
        <div className="col-md-11">
          <h2 className="h1-hp">Key Features</h2>
          <p className="fp p-hp">Explore Haantlay's cutting-edge features that define our commitment to pioneering innovation, our forward-looking futuristic vision, and our dedication to seamless integration. Discover the technological excellence that sets us apart and shapes the future today.</p>
        </div>
        <div className="row row-eq-height justify-content-center">
          <div className="col-lg-4 mb-4">
            <div className="card  ">
              <i className="fas fa-brain" />
              <div className="card-body">
                <h5 className="card-title">Pioneering Innovation</h5>
                <p className="card-text text-white">Haantlay is at the forefront of innovation, constantly pushing boundaries to deliver state-of-the-art solutions. With us, you'll embrace the future today, benefiting from our relentless pursuit of groundbreaking technology.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 mb-4">
            <div className="card  ">
              <i className="fas fa-lightbulb" />
              <div className="card-body">
                <h5 className="card-title">Futuristic Vision</h5>
                <p className="card-text text-white">We envision your success in a tech-driven future. At Haantlay, our forward-thinking approach ensures that your business remains future-proof, ready to tackle the challenges of tomorrow's dynamic tech landscape.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 mb-4">
            <div className="card  ">
              <i className="fas fa-puzzle-piece" />
              <div className="card-body">
                <h5 className="card-title">Seamless Integration</h5>
                <p className="card-text text-white">Our solutions seamlessly merge technology into your operations, enhancing efficiency and adaptability. Haantlay's user-friendly, customized approach ensures that technology empowers your business, making you a leader in the ever-evolving tech world.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};



const JoinUsSection = () => {
  return (
    <section id="join-us">
      <div className="container con-ab">
        <div className="video-container">
          <video autoPlay muted loop id="background-video" poster="img/buil.jpg">
            <source src={joinvid} type="video/webm" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div>
          <h1>Your next starts right here</h1>
        </div>
        <HashLink smooth to={"/careers/"} >JOIN US</HashLink>
      </div>
    </section>
  );
};


const TransSection = () => {
  return (
    <section id="trans">
    <div className="container trans-co">
      <div className="row">
        <div className="col-md-5 cont">
          <h1>Translating technology into a positive impact</h1>
          <p className="p-hp">Our approach allows us to deliver exceptional experiences that drive growth and success for all stakeholders. Let’s rise to new heights with the power of digital transformation.</p>
        </div>
        <div className="col-md-7 num">
          <div className="row che-num">
            <div className="col-md-6">
              <span className="counter" data-target = "274">274+</span>
        <p>Strategic Partnerships</p>
            </div>
            <div className="col-md-6">
              
        <span className="counter" data-target = "421">421+</span>
        <p>Cutting-Edge Solutions</p>
            </div>
            <div className="col-md-6">
              <span className="counter" data-target = "1364">1364+</span>
              <p>Continuous 24/7 Support</p></div>
            <div className="col-md-6">
              <span className="counter" data-target = "18">18+</span>
              <p>Devoted Team</p></div>
          </div>
        </div>
      </div>

    </div>
  </section>
  );
};

// const HelpSection = () => {
//   return (
//     <section id="help">
//       <div className="container">
//         <h1>How can we help you?</h1>
//         <p>Are you ready to push boundaries and explore new frontiers of innovation?</p>
//         <button id="conbtn">Let's Work Together</button>
//       </div>
//     </section>
//   );
// };

const HelpSection = () => {
  return (
    <section id="help">
      <div className="container">
        <h1>How can we help you?</h1>
        <p>Are you ready to push boundaries and explore new frontiers of innovation?</p>
        {/* Use a form with a button */}
        
        
        <HashLink smooth to={"/contactus/"} id="help-btn">Let's Work Together</HashLink>
      </div>
    </section>
  );
};



const App = () => {
  return (
    <div>
      {/* <VideoIntroSection /> */}
      <CarouselComponent />
      <AboutSection />
      <WhyUsSection />
      <TransSection />
      <JoinUsSection />
      <HelpSection />
    </div>
  );
};

export default App;
