import React, { useState, useEffect } from 'react';
import { IoIosMenu, IoIosClose } from "react-icons/io";

import { CiMenuFries } from "react-icons/ci";
import { RiMenu3Fill } from "react-icons/ri";
import { CgMenuRight } from "react-icons/cg";
import { GoPerson } from "react-icons/go";

import {HashLink} from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import logo1 from '../assets/img/logo2.svg';
import logo2 from '../assets/img/logo1.svg';



const logoimage = document.querySelector('#logoimage');


function Header() {
  
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [number, setNumber] = useState(1);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [toggleIconColor, setToggleIconColor] = useState('white');
  const [logoSrc, setLogoSrc] = useState(logo1);


  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      if (scrollTop > lastScrollTop) {
        // Scrolling down, hide the header
        const header = document.getElementById('header');
        if (header) header.style.top = '-70px';
        const pbar = document.querySelector('.p-bar');
        if (pbar) pbar.style.backgroundColor = "#333";
      
        setLogoSrc(logo2);
        const bocc = document.querySelector('#broc');
        bocc.style.color = "#212529";
        const boc = document.querySelector('#borc');
        boc.style.borderColor = "rgba(205,205,205,0.22)";
      

      } else {
        // Scrolling up, show the header
        const header = document.getElementById('header');
        if (header) header.style.top = '0';
        document.querySelectorAll('.ch-li, .ch-li1, .ch-li2, .ch-li3, #t-b-n').forEach(item => {

          // Set color to black
          setNumber(2);
          item.style.color = 'black';
      
          header.style.backgroundColor = "white";
          // Add event listeners for hover
          item.addEventListener('mouseenter', function() {
              // Change border color on hover to black
              this.style.borderColor = 'black';
          });
      
          item.addEventListener('mouseleave', function() {
              // Reset border color when mouse leaves the element
              this.style.borderColor = ''; // Reset to default
          });
        });
        const bbButton = document.querySelector('#nav-btn');
        if (bbButton) bbButton.style.display = 'block';
        const pbar = document.querySelector('.p-bar');
        if (pbar) pbar.style.backgroundColor = '#333';
      }
      setLastScrollTop(scrollTop);
    };

    const handleProgress = () => {
      const scrollPos = window.scrollY;
      const pageHeight = document.documentElement.scrollHeight - window.innerHeight;
      const progress = (scrollPos / pageHeight) * 100;

      if (progress === 0) {

        setLogoSrc(logo1);

        setNumber(1);
        const bbButton = document.querySelector('#nav-btn');
        if (bbButton) bbButton.style.display = 'none';
        document.querySelectorAll('.ch-li, .ch-li1, .ch-li2, .ch-li3').forEach(item => {
          const boc = document.querySelector('#borc');
          boc.style.borderColor = "#ffffff46";
          const bocc = document.querySelector('#broc');
          bocc.style.color = "white";
          item.style.color = 'white'; 
          item.addEventListener('mouseenter', function() {
            // Change border color on hover to black
            this.style.borderColor = 'white';
          });
      
          item.addEventListener('mouseleave', function() {
              // Reset border color when mouse leaves the element
              this.style.borderColor = ''; // Reset to default
          });              
          const header = document.getElementById('header');     
          header.style.backgroundColor = 'transparent';
        });
        const tbn = document.querySelector('#t-b-n');
        if (tbn) tbn.style.color = '#ffff';
        const pbar = document.querySelector('.p-bar');
        if (pbar) pbar.style.backgroundColor = 'transparent';
      }

      const isScrollingUp = scrollPos < prevScrollPos;
      let margin = 0;
      if (isScrollingUp) {
        margin = 70;
      }
      const pbar = document.querySelector('.p-bar');
      if (pbar) pbar.style.marginTop = margin + 'px';
      const filled = document.querySelector('.filled');
      if (filled) filled.style.width = progress + '%';
      setPrevScrollPos(scrollPos);
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('scroll', handleProgress);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('scroll', handleProgress);
    };
  }, [lastScrollTop, prevScrollPos]);

  useEffect(() => {
    const toggleButton = document.querySelector('#t-b-n');
    if (toggleButton) {
      toggleButton.style.color = toggleIconColor;
    }
  }, [toggleIconColor]);

  const toggleMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
    if (number === 1) {
      setToggleIconColor(toggleIconColor === 'white' ? 'black' : 'white');
    }
  };


  const closeMenu = () => {
    setIsMobileMenuOpen(false);
  };

  window.onscroll = () => {
    closeMenu();
  };

  return (
    <header id="header">
      {/* Header content */}
      <div className="container">
        <div className="n-navbar">
            <div id="logo">
            <HashLink smooth to="/"><img src={logoSrc} alt="" title="" id="logoimage" /></HashLink>
            </div>
          
          <ul className="n-links">
            <li><HashLink smooth to={"/en/company-overview/"} className="ch-li">About</HashLink></li>
            <li><HashLink smooth to={"/careers/"} className="ch-li1">Careers</HashLink></li>
            <li><HashLink smooth to={"/services/"} className="ch-li2">H-solutions</HashLink></li>
            <li><HashLink smooth to={"/source/"} className="ch-li3">Company News</HashLink></li>
            
          </ul>

          
          <HashLink smooth to="/contactus/" className="nav-btn" id="nav-btn" >Get in touch</HashLink>

          {/* <div className="toogle-btn" id="t-b-n" onClick={toggleMenu}>
            {isMobileMenuOpen ? <IoIosClose /> : <IoIosMenu />}
          </div> */}
        
          <div className='lo-t'>
          {/* <HashLink smooth to="/contactus/" className="act"  >Get in touch</HashLink> */}
          <div className='border-l' id='borc' > 

          <HashLink smooth to="http://portfolio.haantlay.com/"> 
            <div class="pro" id='broc'><GoPerson /></div> 
          </HashLink>
          </div>
 
     
            <div className="toogle-btn" id="t-b-n" onClick={toggleMenu}>
              {isMobileMenuOpen ? <IoIosClose /> : <IoIosMenu />}
            </div>
          </div>
          
        </div>
      </div>

      {/* Mobile menu */}
      <div className={`n-m-menu ${isMobileMenuOpen ? 'open' : ''}`}>
      <div id="mobilelogo">
        <div className="container">
          <div id="mlogo">
            <img src={logo2} alt="" title="" id="mobilelogoimage" />
          </div>
        </div>
      </div>




        <ul className="ul-links-a">

          <li><HashLink smooth to="/en-PK/#about" onClick={closeMenu}>About Haantlay</HashLink></li>
          <li><HashLink smooth to="/en/company-overview/" onClick={closeMenu}>Who We Are</HashLink></li>
          <li><HashLink smooth to="/careers/" onClick={closeMenu}>Careers</HashLink></li>
          <li><HashLink smooth to="/services/" onClick={closeMenu}>H-solutions</HashLink></li>
          <li><HashLink smooth to="/source/" onClick={closeMenu}>Company News</HashLink></li>
          <li><HashLink smooth to="https://www.portfolio.haantlay.com/" onClick={closeMenu}>Create a Portfolio</HashLink></li>
          <li><HashLink smooth to="/en/legal/terms-of-use/" onClick={closeMenu}>Legal</HashLink></li>
        </ul>
        <HashLink smooth to="/contactus/" className="n-btn" onClick={closeMenu}>Get in touch</HashLink>

        
      </div>

      {/* Progress bar */}
      <div className="p-bar">
        <div className="filled"></div>
      </div>
    </header>
  );
}

export default Header;