import React from 'react';

import Subscribe from "../componenets/subscribition.jsx";

import { FaFacebookF } from "react-icons/fa6";
import { GrLinkedin } from "react-icons/gr";
import { BsTwitterX } from "react-icons/bs";
import { SiInstagram } from "react-icons/si";
import { HashLink } from 'react-router-hash-link';

import { BsArrowUpShort } from "react-icons/bs";

function Footer() {
  return (
    <footer id="footer">
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-md-6 fbbt">
              <h1>Follow Haantlay</h1>
              <div className="social-links-fs">
                <a href="https://www.facebook.com/profile.php?id=100095130312866&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer" className="facebook"><FaFacebookF /></a>
                <a href="https://x.com/haantlay?t=7THwqmT5whC1-Z82gw9e1w&s=09" target="_blank" rel="noopener noreferrer" className="twitter"><BsTwitterX /></a>
                <a href="https://www.linkedin.com/company/haantlay/" target="_blank" rel="noopener noreferrer" className="linkedin"><GrLinkedin /></a>
                <a href="https://instagram.com/haantlay?igshid=OGQ5ZDc2ODk2ZA==" target="_blank" rel="noopener noreferrer" className="instagram"><SiInstagram /></a>
              </div>
            </div>
            <div className="col-md-6 fbt">
              <HashLink smooth to= '#'>
                <div className="btp">
                  {/* <i> */}
                  <BsArrowUpShort />
                  {/* </i> */}
                  <h1>Back To Top</h1>
                </div>
              </HashLink>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-md-3 footer-contact">
              <h1>Quick Links</h1>
              <div className="fcl">
                <HashLink smooth to={"/"} className="lk" >Haantlay</HashLink>
              </div>
              <div className="fcl">
                <HashLink smooth to={"/en-PK/#about"} className="lk" >Discover Haantlay</HashLink>
              </div>
              <div className="fcl">
                <HashLink smooth to={"/en-PK/#why-us"} className="lk" >Why Haantlay</HashLink>
              </div>
              <div className="fcl">
                <HashLink smooth to={"/careers/"} className="lk" >Join Us</HashLink>
              </div>
              <div className="fcl">
                <HashLink smooth to={"/contactus/"} className="lk" >Get In Touch</HashLink>
              </div>
            </div>
            <div className="col-md-3 footer-contact cufts">
              <h1>Company</h1>
              <div className="fcl">
                <HashLink smooth to={"/en-PK/#about"} className="lk" >About</HashLink>
              </div>
              <div className="fcl">
                <HashLink smooth to={"/en/company-overview/"} className="lk" >Who We Are</HashLink>
              </div>
              <div className="fcl">
                <HashLink smooth to={"/en/company-overview/#the-h-way"} className="lk" >The Haantlay's Way</HashLink>
              </div>
              <div className="fcl">
                <HashLink smooth to={"/source/"} className="lk" >Company News</HashLink>
              </div>
              <div className="fcl">
                <HashLink smooth to={"/en/legal/terms-of-use/"} className="lk" >Legal</HashLink>
              </div>
            </div>
            <div className="col-md-3 footer-contact">
              <h1>Careers</h1>
              <div className="fcl">
                <HashLink smooth to={"/careers/"} className="lk" >Join Us</HashLink>
              </div>
              <div className="fcl">
                <HashLink smooth to={"/careers/#car-det"} className="lk" >People At Haantlay</HashLink>
              </div>
              <div className="fcl">
                <HashLink smooth to={"/careers/#cr-gl"} className="lk" >Our Global Presence</HashLink>
              </div>
              <div className="fcl">
                <HashLink smooth to={"/careers/#c-ic-s"} className="lk" >Our Hiring Process</HashLink>
              </div> 
              <div className="fcl">
                <HashLink smooth to={"https://www.portfolio.haantlay.com/"} className="lk" >Create a Portfolio</HashLink>
              </div>
            </div>
            <div className="col-md-3 footer-contact cufts">
              <h1>Services</h1>
              <div className="fcl">
                <HashLink smooth to={"/services/"} className="lk" >What we offer</HashLink>
              </div>
              <div className="fcl">
                <HashLink smooth to={"/services/#o-der-det"} className="lk" >Our Procedure</HashLink>
              </div>
              <div className="fcl">
                <HashLink smooth to={"/services/#getintouch"} className="lk" >Want To Order</HashLink>
              </div>
              <div className="fcl">
                <HashLink smooth to={"/services/#getintouch"} className="lk" >Get A Quote</HashLink>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-top pt-0">
        <div className="container">
            <div>
              <Subscribe />
            </div>
          <div className="row">
            <div className="col-md-2 text-left pri">
              <span><HashLink smooth to = {"/en/privacystatement/"} >Privacy Policy</HashLink></span>
            </div>
            <div className="col-md-2 text-left pri">
              <span><HashLink smooth to = {"/en/legal/terms-of-use/"} >Terms & Conditions</HashLink></span>
            </div>
            <div className="col-md-2 text-left pri">
              <span><HashLink smooth to = {"/en/cookie-policy/"} >Cookie Policy</HashLink></span>
            </div>
            <div className="copy col-md-6 co">
              &copy; 2024 Haantlay. All Rights Reserved.
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
