import React, { useState, useEffect } from 'react';

const ContactForm = () => {
    useEffect(() => {
        document.title = "Get in Touch | Reach Out to Haantlay";
      }, []);
    const [formData, setFormData] = useState({
        name: '',
        city: '',
        email: '',
        phone: '',
        company: '',
        title: '',
        description: ''
    });
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Email validation
        const emailExp = /^[^\s()<>@,;:\/]+@\w[\w\.-]+\.[a-z]{2,}$/i;
        const emailError = !formData.email || !emailExp.test(formData.email) ? 'Please enter a valid email' : '';

        setErrors({
            ...errors,
            email: emailError
        });

        // Check if there are any errors
        for (const key in formData) {
            if (formData[key] === '' || (key === 'email' && emailError !== '')) {
             
                return;
            }
        }

        // Assuming form submission logic here
        try {
            const response = await fetch('/contact-us/contact.php', {
                method: 'POST',
                body: JSON.stringify(formData),
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                window.open('/L2pN9gW4dC1tA7kF0Z6xJ8iQ3wE5rTL2pN9gW4dC1tA7kF0Z6xJ8iQ3wE5rTL2pN9gW4dC1tA7kF0Z6xJ8iQ3wE5rTL2pN9gW4dC1tA7kF0Z6xJ8iQ3wE5rTL2pN9gW4dC1tA7kF0Z6xJ8iQ3wE5rT', '_self');
            } else {
                window.open('/R8uV3yS7wQ6hT4iG2J9oH6gY2fR7kIR8uV3yS7wQ6hT4iG2J9oH6gY2fR7kIR8uV3yS7wQ6hT4iG2J9oH6gY2fR7kIR8uV3yS7wQ6hT4iG2J9oH6gY2fR7kIR8uV3yS7wQ6hT4iG2J9oH6gY2fR7kI', '_self');
            }
        } catch (error) {
            console.error('Error:', error);
            window.open('/R8uV3yS7wQ6hT4iG2J9oH6gY2fR7kIR8uV3yS7wQ6hT4iG2J9oH6gY2fR7kIR8uV3yS7wQ6hT4iG2J9oH6gY2fR7kIR8uV3yS7wQ6hT4iG2J9oH6gY2fR7kIR8uV3yS7wQ6hT4iG2J9oH6gY2fR7kI', '_self');
        }
    };

    return (
        
        <>
            <section id="getintouchf" className="">
                <div className="container">
                    <div className="col-md-11 ">
                        <p className="fp">GET IN TOUCH</p>
                        <h2>How can we help you?</h2>
                    </div>
                </div>
            </section>

            <section id="getintouch" className="section-bg">
                <div className="container">
                    <div className="form col-md-6">
                        <div>
                            <p>Please fill the form below</p>
                        </div>
                        <form className="contactForm" onSubmit={handleSubmit}>
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <input
                                        className={`form-control ${errors.name && 'error'}`}
                                        type="text"
                                        name="name"
                                        placeholder="Your name*"
                                        value={formData.name}
                                        onChange={handleChange}
                                        minLength="5"
                                        required
                                    />
                                </div>
                                <div className="form-group col-md-6">
                                    <input
                                        className={`form-control ${errors.city && 'error'}`}
                                        type="text"
                                        name="city"
                                        placeholder="City*"
                                        value={formData.city}
                                        onChange={handleChange}
                                        minLength="3"
                                        required
                                    />
                                </div>
                                <div className="form-group col-md-6">
                                    <input
                                        className={`form-control ${errors.email && 'error'}`}
                                        type="email"
                                        name="email"
                                        placeholder="Email address*"
                                        value={formData.email}
                                        onChange={handleChange}
                                        required
                                    />
                                    {errors.email && <div className="validation-s" style={{ display: 'block' }}>{errors.email}</div>}
                                </div>
                                <div className="form-group col-md-6">
                                    <input
                                        className="form-control"
                                        type="tel"
                                        name="phone"
                                        placeholder="Phone number*"
                                        value={formData.phone}
                                        onChange={handleChange}
                                        pattern="\+[0-9]{7,15}"
                                        title="Start with '+' and 7 to 15 digits without hyphens (-) or spaces allowed."
                                        required
                                    />
                                </div>
                                <div className="form-group col-md-6">
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="company"
                                        placeholder="Company name*"
                                        value={formData.company}
                                        onChange={handleChange}
                                        minLength="3"
                                        required
                                    />
                                </div>
                                <div className="form-group col-md-6">
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="title"
                                        placeholder="Your title/role*"
                                        value={formData.title}
                                        onChange={handleChange}
                                        minLength="3"
                                        required
                                    />
                                </div>
                            </div>
                            <textarea
                                className="form-control"
                                name="description"
                                id="contact-description"
                                rows="4"
                                placeholder="Please tell us a bit about what you're looking for"
                                value={formData.description}
                                onChange={handleChange}
                                minLength="4"
                                required
                            ></textarea>
                            <button type="submit" id="button">Submit</button>
                        </form>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ContactForm;
