import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";


import "./assets/lib/bootstrap/css/bootstrap.min.css";
import "./assets/css/style.css";
import "./assets/css/blogs.css";
import "./assets/css/carrers.css";
import "./assets/css/company-overview.css";
import "./assets/css/slider.css";
import './assets/css/carousel.min.css';

import "./assets/js/script.js";

import Scrolltotop from "./assets/js/scrolltotop.js";


import Header from"./componenets/header.jsx";
import Home from"./componenets/home.jsx";
import Footer from"./componenets/footer.jsx";
import Contact from"./componenets/contact.jsx";
import Services from"./componenets/services.jsx";
import Careers from"./componenets/careers.jsx";
import Company from"./componenets/company-overview.jsx";
import Privacy from"./componenets/privacy.jsx";
import Cookies from"./componenets/cookies.jsx";
import Terms from"./componenets/terms.jsx";
import Ccookies from"./componenets/cookies-check.jsx";
import Preloader from"./componenets/preloader.jsx";
import Cnews from"./componenets/newsroom.jsx";
import News1 from"./componenets/blogs/blog1.jsx";
import News2 from"./componenets/blogs/blog2.jsx";
import Unsub from"./componenets/process/unsub.jsx";
import Sub from"./componenets/process/sub.jsx";
import Con from"./componenets/process/cson.jsx";
import Uncon from"./componenets/process/uncon.jsx";
import Conf from"./componenets/process/conf.jsx";
import Unconf from"./componenets/process/unconf.jsx";
import Notfound from"../src/404.jsx";










function App() {

  return (
    <Router>
      <Ccookies />
      <Scrolltotop />
      <Header />
      <Routes>
        <Route path="/" element={<Navigate to="/en-PK/" />} />
        <Route path="/en-PK/" element={<Home />} />
        <Route path="/contactus/" element={<Contact />} />
        <Route path="/services/" element={<Services />} />
        <Route path="/careers/" element={<Careers />} />
        <Route path="/en/company-overview/" element={<Company />} />
        <Route path="/en/privacystatement/" element={<Privacy />} />
        <Route path="/en/cookie-policy/" element={<Cookies />} />
        <Route path="/en/legal/terms-of-use/" element={<Terms />} />
        <Route path="/source/" element={<Cnews />} />
        <Route path="/source/topics/company-news=Haantlay-Secures-2nd-Position-at-IEEE-Expo" element={<News1 />} />
        <Route path="/source/topics/company-news=A-Triumph-in-Speed-Coding-at-the-WEC-Olympiad" element={<News2 />} />
        <Route path="/X9lK7rP3vE5nQ1mW3B0zG8hF2jT6uVX9lK7rP3vE5nQ1mW3B0zG8hF2jT6uVX9lK7rP3vE5nQ1mW3B0zG8hF2jT6uVX9lK7rP3vE5nQ1mW3B0zG8hF2jT6uVX9lK7rP3vE5nQ1mW3B0zG8hF2jT6uVX9lK7rP3vE5nQ1mW3B0zG8hF2jT6uV" element={<Unsub />} />
        <Route path="/t4jH8aM6cT2sY9lR7D5vK1bX3nZ0mPF4jH8aM6cT2sY9lR7D5vK1bX3nZ0mPF4jH8aM6cT2sY9lR7D5vK1bX3nZ0mPF4jH8aM6cT2sY9lR7D5vK1bX3nZ0mPF4jH8aM6cT2sY9lR7D5vK1bX3nZ0mP" element={<Sub />} />
        <Route path="/L2pN9gW4dC1tA7kF0Z6xJ8iQ3wE5rTL2pN9gW4dC1tA7kF0Z6xJ8iQ3wE5rTL2pN9gW4dC1tA7kF0Z6xJ8iQ3wE5rTL2pN9gW4dC1tA7kF0Z6xJ8iQ3wE5rTL2pN9gW4dC1tA7kF0Z6xJ8iQ3wE5rT" element={<Con />} />
        <Route path="/R8uV3yS7wQ6hT4iG2J9oH6gY2fR7kIR8uV3yS7wQ6hT4iG2J9oH6gY2fR7kIR8uV3yS7wQ6hT4iG2J9oH6gY2fR7kIR8uV3yS7wQ6hT4iG2J9oH6gY2fR7kIR8uV3yS7wQ6hT4iG2J9oH6gY2fR7kI" element={<Uncon />} />
        <Route path="/M5eI1fO2bP9qU7sH3Y8xK1lN4gW2dCM5eI1fO2bP9qU7sH3Y8xK1lN4gW2dCM5eI1fO2bP9qU7sH3Y8xK1lN4gW2dCM5eI1fO2bP9qU7sH3Y8xK1lN4gW2dCM5eI1fO2bP9qU7sH3Y8xK1lN4gW2dC" element={<Conf />} />
        <Route path="/K3vW1dN9jF7gR5pL2C4mX0lK7rP3vEK3vW1dN9jF7gR5pL2C4mX0lK7rP3vEK3vW1dN9jF7gR5pL2C4mX0lK7rP3vEK3vW1dN9jF7gR5pL2C4mX0lK7rP3vEK3vW1dN9jF7gR5pL2C4mX0lK7rP3vE" element={<Unconf />} />
        <Route path="*" element={<Notfound />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
