import React, { useState, useEffect } from 'react';
import {HashLink} from 'react-router-hash-link';

import { BiAnalyse } from "react-icons/bi";

import { MdOutlineDesignServices } from "react-icons/md";

import { BsFileEarmarkCode } from "react-icons/bs";

import { IoBugOutline } from "react-icons/io5";

import { GrHostMaintenance } from "react-icons/gr";

import { RiSeoLine } from "react-icons/ri";



const HelpSection = () => {
    
    return (
      <section id="help">
        <div className="container">
          <h1>How can we help you?</h1>
          <p>Are you ready to push boundaries and explore new frontiers of innovation?</p>
          {/* Use a form with a button */}
          
          
          <HashLink smooth to={"/contactus/"} id="help-btn">Let's Work Together</HashLink>
        </div>
      </section>
    );
  };
  

const handleInputChange = (e, setFormData) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
        ...prevState,
        [name]: value
    }));
};

const subcategories = {
    web: ["Informational Websites", "E-Commerce", "Blogs and Forums", "Portfolio Websites", "Landing Pages"],
    apps: ["Mobile Apps", "Web Apps", "Gaming Apps"],
    software: ["Operating Systems", "Productivity Software", "Security Software", "Development Tools", "Database Management", "Graphic and Multimedia Software"]
};

const showSubcategories = (formData) => {
    const mainCategory = formData.mainCategory;
    return subcategories[mainCategory].map((subcategory, index) => (
        <option key={index} value={subcategory}>{subcategory}</option>
    ));
};

const ContactForm = () => {
    useEffect(() => {
        document.title = "Expertise, Innovation, and Tailored Solutions | Our Services";
      }, []);
    const [formData, setFormData] = useState({
        name: '',
        city: '',
        email: '',
        phone: '',
        company: '',
  
        description: '',
        mainCategory: '',
        subCategory: '',
        budget: '',
    });
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Email validation
        const emailExp = /^[^\s()<>@,;:\/]+@\w[\w\.-]+\.[a-z]{2,}$/i;
        const emailError = !formData.email || !emailExp.test(formData.email) ? 'Please enter a valid email' : '';

        setErrors({
            ...errors,
            email: emailError
        });

        // Check if there are any errors
    
        for (const key in formData) {
            if (formData[key] === '' || (key === 'email' && emailError !== '')) {
             
                return;
            }
        }
        // Assuming form submission logic here
        try {
            const response = await fetch('/contact-us/order.php', {
                method: 'POST',
                body: JSON.stringify(formData),
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                window.open('/M5eI1fO2bP9qU7sH3Y8xK1lN4gW2dCM5eI1fO2bP9qU7sH3Y8xK1lN4gW2dCM5eI1fO2bP9qU7sH3Y8xK1lN4gW2dCM5eI1fO2bP9qU7sH3Y8xK1lN4gW2dCM5eI1fO2bP9qU7sH3Y8xK1lN4gW2dC', '_self');
            } else {
                window.open('/K3vW1dN9jF7gR5pL2C4mX0lK7rP3vEK3vW1dN9jF7gR5pL2C4mX0lK7rP3vEK3vW1dN9jF7gR5pL2C4mX0lK7rP3vEK3vW1dN9jF7gR5pL2C4mX0lK7rP3vEK3vW1dN9jF7gR5pL2C4mX0lK7rP3vE', '_self');
            }
        } catch (error) {
            console.error('Error:', error);
            window.open('/K3vW1dN9jF7gR5pL2C4mX0lK7rP3vEK3vW1dN9jF7gR5pL2C4mX0lK7rP3vEK3vW1dN9jF7gR5pL2C4mX0lK7rP3vEK3vW1dN9jF7gR5pL2C4mX0lK7rP3vEK3vW1dN9jF7gR5pL2C4mX0lK7rP3vE', '_self');
        }


    };

    return (
        
        <>


            <section id="services" className="">
                <div className="container">
                    <div className="col-md-12  front-div-op">
                        <h2>H - <br /> Solutions</h2>
                        <HashLink smooth to={"#getintouch"} id="contact-button-nnn">SECURE YOUR SPOT</HashLink>
                    </div>
                </div>
            </section>

            <section id="ser-det">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 vmc div1 pr-5 pl-0">
                            <h2 className="h1-hp">Order IT Services from Haantlay</h2>
                            <p className="p-hp">At Haantlay, we're your trusted partner in shaping the technology landscape of tomorrow. Our commitment to innovation and excellence drives us to deliver cutting-edge IT services that empower your business. With a dedicated team of experts and a passion for technology, we offer a wide range of IT solutions tailored to meet your unique needs.</p>
                        </div>
                        <div className="col-md-6 p-0 vmimg div2 svimg">
                            <img src={require('../assets/img/www.webp')} alt="" className="img-fluid mvimg" />
                        </div>
                    </div>
                </div>
            </section>

            <section id="o-der-det">
                <div className="container h-ed col-md-10">
                    <p>Our Software Development Process</p>
                    <h2>The software development process is a structured path to create, test, and deploy software.</h2>
                </div>
                <div className="container">
                    <div className="timeline">
                        <div className="timeline-row">
                            <div className="timeline-time">
                                Step number 1
                            </div>
                            <div className="timeline-content">
                                <i><BiAnalyse /></i>
                                <h4>Planning & Analysis</h4>
                                <p>We understand what you want, complete our preliminary research and present you with the perfect solution. We meet the client and the users to gather requirements and data that could help us shape our solution.</p>
                                <div className="thumbs">
                                    <img className="img-fluid rounded" src={require("../assets/img/icons/ic1.jpg" )}/>
                                    <img className="img-fluid rounded" src={require("../assets/img/icons/ic2.jpg" )}/>
                                    <img className="img-fluid rounded" src={require("../assets/img/icons/ic3.jpeg")} />
                                </div>
                            </div>
                        </div>
                        <div className="timeline-row">
                            <div className="timeline-time">
                                Step number 2
                            </div>
                            <div className="timeline-content">
                                <i><MdOutlineDesignServices /></i>
                                <h4>Design</h4>
                                <p>We build the information architecture and lo-fidelity prototypes that help the client visualize the final product. We go back and forth to come up with a wireframe that satisfies the client needs and then proceed to hi-fidelity prototyping.</p>
                                <div className="thumbs">
                                    <img className="img-fluid rounded" src={require("../assets/img/icons/ic4.jpg" )}/>
                                    <img className="img-fluid rounded" src={require("../assets/img/icons/ic5.jpeg")} />
                                    <img className="img-fluid rounded" src={require("../assets/img/icons/ic6.jpeg")} />
                                </div>
                            </div>
                        </div>
                        <div className="timeline-row">
                            <div className="timeline-time">
                                Step number 3
                            </div>
                            <div className="timeline-content">
                                <i><BsFileEarmarkCode /></i>
                                <h4>Development</h4>
                                <p>We choose the appropriate technology and framework to kickstart the development process. We practice agile development and believe in effective communication with the client through multiple project management tools that ensure transparency.</p>
                                <div className="thumbs">
                                    <img className="img-fluid rounded" src={require("../assets/img/icons/ic7.jpg" )}/>
                                    <img className="img-fluid rounded" src={require("../assets/img/icons/ic8.jpeg")} />
                                    <img className="img-fluid rounded" src={require("../assets/img/icons/ic9.jpeg")} />
                                </div>
                            </div>
                        </div>
                        <div className="timeline-row">
                            <div className="timeline-time">
                                Step number 4
                            </div>
                            <div className="timeline-content">
                                <i><IoBugOutline /></i>
                                <h4>Testing & Implementation</h4>
                                <p>We test our products rigorously to ensure that it is error and bug free. We focus not only on automated testing but also a rigorous cycle of user testing. Following a successful pilot study, we implement the solution and provide training to the required personnel.</p>
                                <div className="thumbs">
                                    <img className="img-fluid rounded" src={require("../assets/img/icons/ic10.jpeg")} />
                                    <img className="img-fluid rounded" src={require("../assets/img/icons/ic11.jpeg")} />
                                    <img className="img-fluid rounded" src={require("../assets/img/icons/ic12.jpeg")} />
                                </div>
                            </div>
                        </div>
                        <div className="timeline-row">
                            <div className="timeline-time">
                                Step number 5
                            </div>
                            <div className="timeline-content">
                                <i><GrHostMaintenance /></i>
                                <h4>Maintenance</h4>
                                <p>Once our software passes through all the stages without issues, it undergoes a maintenance process where it will be maintained and upgraded from time to time to adapt to changes.</p>
                                <div className="thumbs">
                                    <img className="img-fluid rounded" src={require("../assets/img/icons/ic13.jpeg")} />
                                    <img className="img-fluid rounded" src={require("../assets/img/icons/ic14.jpeg")} />
                                    <img className="img-fluid rounded" src={require("../assets/img/icons/ic15.jpeg")} />
                                </div>
                            </div>
                        </div>
                        <div className="timeline-row">
                            <div className="timeline-time">
                                Step number 6
                            </div>
                            <div className="timeline-content">
                                <i><RiSeoLine /></i>
                                <h4>Digital marketing & SEO</h4>
                                <p>If our client chooses, we design a holistic marketing plan that compliments the process of SEO.</p>
                                <div className="thumbs">
                                    <img className="img-fluid rounded" src={require("../assets/img/icons/ic16.jpeg")} />
                                    <img className="img-fluid rounded" src={require("../assets/img/icons/ic17.jpeg")} />
                                    <img className="img-fluid rounded" src={require("../assets/img/icons/ic18.jpeg")} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <section id="getintouch" className="section-bg">
                <div className="container">
                    <div className="form col-md-6">
                        <div>
                            <p>Please fill the form below</p>
                        </div>
                        <form className="contactForm" onSubmit={handleSubmit}>
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="name"
                                        placeholder="Your name*"
                                        value={formData.name}
                                        onChange={handleChange}
                                        minLength="5"
                                        required
                                    />
                                </div>
                                <div className="form-group col-md-6">
                                    <input
                                        className={`form-control ${errors.email && 'error'}`}
                                        type="email"
                                        name="email"
                                        placeholder="Email address*"
                                        value={formData.email}
                                        onChange={handleChange}
                                        required
                                    />
                                    {errors.email && <div className="validation-s" style={{ display: 'block' }}>{errors.email}</div>}
                                </div>
                                <div className="form-group col-md-6">
                                    <input
                                        className="form-control"
                                        type="tel"
                                        name="phone"
                                        placeholder="Phone number*"
                                        value={formData.phone}
                                        onChange={handleChange}
                                        pattern="\+[0-9]{7,15}"
                                        title="Start with '+' and 7 to 15 digits without hyphens (-) or spaces allowed."
                                        required
                                    />
                                </div>
                                <div className="form-group col-md-6">
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="company"
                                        placeholder="Company name*"
                                        value={formData.company}
                                        onChange={handleChange}
                                        minLength="3"
                                        required
                                    />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <select id="mainCategory" name="mainCategory" className="form-control" required value={formData.mainCategory} onChange={(e) => handleInputChange(e, setFormData)}>
                                        <option disabled selected value="">Project Type*</option>
                                        <option value="web">Web</option>
                                        <option value="apps">Apps</option>
                                        <option value="software">Software</option>
                                    </select>
                                </div>
                                <div className="form-group col-md-6">
                                    <select id="subCategory" name="subCategory" className="form-control" required value={formData.subCategory} onChange={handleChange}>
                                        <option disabled selected value="">Project Category*</option>
                                        {formData.mainCategory && showSubcategories(formData)}
                                    </select>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-12">
                                    <select id="budget" className="form-control" name="budget" required value={formData.budget} onChange={handleChange}>
                                        <option disabled selected value="">Please select the Budget range</option>
                                        <option value="$5 - $20">$5 - $20</option>
                                        <option value="$20 - $40">$20 - $40</option>
                                        <option value="$40 - $60">$40 - $60</option>
                                        <option value="$60 - $80">$60 - $80</option>
                                        <option value="$80 - $100">$80 - $100</option>
                                        <option value="$100 - $150">$100 - $150</option>
                                        <option value="$150 - $300">$150 - $300</option>
                                        <option value="$300 - $450">$300 - $450</option>
                                        <option value="$450 - $600">$450 - $600</option>
                                        <option value="$600 - $750">$600 - $750</option>
                                        <option value="$750 - $900">$750 - $900</option>
                                        <option value="$900 - $1000">$900 - $1000</option>
                                        <option value="$1000 - $1500">$1000 - $1500</option>
                                        <option value="$1500 - $2000">$1500 - $2000</option>
                                        <option value="$2000 - $4000">$2000 - $4000</option>
                                        <option value="$4000 - $6000">$4000 - $6000</option>
                                        <option value="$6000 - $8000">$6000 - $8000</option>
                                        <option value="$8000 - $10000">$8000 - $10000</option>
                                        <option value="$10000+">$10000+</option>
                                    </select>
                                </div>
                            </div>




                            
                            <textarea
                                className="form-control"
                                name="description"
                                id="contact-description"
                                rows="4"
                                placeholder="Please tell us a bit about what you're looking for"
                                value={formData.description}
                                onChange={handleChange}
                                minLength="4"
                                required
                            ></textarea>
                            <button type="submit" id="button">Submit</button>
                        </form>
                    </div>
                </div>
            </section>
            <HelpSection />
        </>
    );
};

export default ContactForm;